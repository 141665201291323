<template :v-if="contentsPath">
  <PlayMenu :url="pathToList" ref="playlist"/>
</template>

<script>
  import PlayMenu from '@/components/PlayMenu'
  export default {
    props: {
    },
    components: {
      PlayMenu,
    },
    data: () => ({
      pathToList: ""
    }),
    methods: {
      setURL(query, params){
        console.log("this", this)
        console.log("params", params)
        if (query) {
          console.log("query",query)
          if ('url' in query){
            this.pathToList = query.url
          } else {
            this.pathToList = this.$home // "https://raw.githubusercontent.com/UedaTakeyuki/HS2playlist/main/home"
          }
        } else {
          this.pathToList = this.$home // "https://raw.githubusercontent.com/UedaTakeyuki/HS2playlist/main/home"
        }
        console.log("this.pathToList",this.pathToList)
        console.log("this.url", this.url)
      }
    },
    watch: {
      $route(to) {
        console.log("to",to)
        this.$refs.playlist.openedPanel = ""
        this.setURL(to.query, to.params)
      }
    },

    mounted: function(){
      console.log("route", this.$route)
      this.setURL(this.$route.query, this.$route.params)
    }
  }
</script>