<template>
  <!-- Comment -->
  <v-card
    class="mx-auto"
    max-width="500"
  >
    <v-card-title>{{title}}</v-card-title>

    <v-expansion-panels
      v-model="openedPanel" 
      accordion tile
    >
      <v-expansion-panel
        v-for="(item, i) in items"
        :key="i"
      >
        <Menu v-if='"link" in item' 
          :item="item"
          @move-request="redirect"
        />
        <Content v-if='"content" in item' :item="item"/>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>


<script>
import {EncLst} from 'enclst-core-js'
import Content from "./Content.vue"
//import Content2 from "./Content2.vue"
import Menu from "./Menu.vue"
//import Menu2 from "./Menu2.vue"
// var sprintf = require('sprintf-js').sprintf

function makeHS2ItemsFromEncLstItems(enclstItems){
  /**
   * @returns {object[]} [{content: text:  link: },…
   */
  let hs2Items = []

  enclstItems.forEach(function(enclstItem){
    let hs2Item = {}

    const enclstValue = enclstItem.value
    // positional params
    if (enclstValue.hasPositinalValues()){

      // first value: content or link
      if (isContent(enclstValue.first())){
        hs2Item.content = enclstValue.first()
      } else {
        hs2Item.link = enclstValue.first()
      }
      // 2nd value: flag
      if (enclstValue.positionalValues.length > 1){
        if (enclstValue.positionalValues[1] == "live"){
          hs2Item.live = true
        }
      }
    }

    // named param
    if (enclstValue.hasNamedValues()){
      if (enclstValue.namedValues["live"] == "true"){
        hs2Item.live = true
      }
    }

    // title
    if ("" != enclstItem.title){
      hs2Item.text = enclstItem.title
    }
    hs2Items.push(hs2Item)
  })

  return hs2Items
}

function isContent(s){
  if (s.substring(0,7) == "http://" || s.substring(0,8) == "https://" || s.substring(0,6) == "file://"){
    return false
  } else if (s.substring(0,2) == "./" || s.substring(0,1) == "/") {
    return false
  } else if (s.indexOf('/') != -1){
    return false
  } else if (s.endsWith('.enclst')){
    return false
  } else if (s.length == 11){
    return true
  } else if ((s.length == 34 || s.length == 18) && s.substring(0,2) == "PL"){
    return true
  } else {
    return false
  }
}

/**
 * Calculate subsequent URL from the Current URL and Next path

* @param {string} currentURL Current URL 
  * @param {string} path Next path
  * @param {string} base_url Base url of this path, or nil.
  * @returns {string} created URL as follows:
  * <ul>
  *   <li> if path is started from "http://", just return paht</li>
  *   <li> else if path is started from "/"
  *   <ul>
  *     <li> if base_url is not specified, return CurrentURL + path
  *     <li> if base_url is specified, return base_url + path
  *   </ul>
  *   <li> else, return URL(path, currentURL)
  * </ul>
  * 
  */
  function makeURLfromCurrentURLandPath(currentURL, path, base_url){
  if (path.substring(0,6) == "http://"){
    // path is full url
    return path
  } else if (path[0] == "/") {
    console.log("base_url",base_url)
    // path is full path
    if (base_url == undefined) {
      base_url = (new URL(currentURL)).origin
      console.log("base_url",base_url)
    }
    console.log("base_url",base_url)
    console.log("length",base_url.length)
    console.log("base_url[base_url.lenght -1]",base_url[base_url.length -1])
    if (base_url[base_url.length -1] == '/'){
//        base_url = base_url.substring(0, base_url.length -1)
      base_url = base_url.slice(0, -1)
      console.log("base_url",base_url)
    }
    return base_url + path
  } else {
    // path is relative path
    return (new URL(path, currentURL).toString())
  }
}

import PlayBackPosition from "@/mixins/playbackposition"
export default {
  components: {
    Content,
//    Content2,
    Menu,
//    Menu2
  },
  mixins: [PlayBackPosition],
  props: {
    url: String,
  },
  data: () => ({
    title: "",
    items: "",
    openedPanel: ""
  }),
  methods: {
    move: async function(url){
      console.log("url",url)
      const res = await fetch(url)
      if (res.status == 200) {
        console.log("isEnclst", this.isEnclst)
        if (this.isEnclst){
          // get enclst as text
          const data = await res.text()
          console.log("url", url)
          console.log("data",data)
          let enclst = new EncLst(data)
          console.log("enclst",enclst)
          this.title = enclst.title
          this.items = makeHS2ItemsFromEncLstItems(enclst.items)
          console.log("items",this.items)
        } else {
          // get data as json
          const data = await res.json();
          console.log("data", data)
          this.items = data.items
          if (data.title){
            this.title = data.title
            console.log("title", this.title)
          }
        }
      } else {
        console.log("err", res.status)
      }

      // update items with the status of viewing 
      this.AskLatestPlayBackPositions()

    },
    redirect: function(path){
      console.log("path", path)
      console.log("this.url", this.url)
//      let nextURL = EncLst.makeURLfromCurrentURLandPath(this.url, path, this.$base_url)
      let nextURL = makeURLfromCurrentURLandPath(this.url, path, this.$base_url)

      console.log("nextURL", nextURL)
      //https://stackoverflow.com/questions/62462276/how-to-solve-avoided-redundant-navigation-to-current-location-error-in-vue
      this.$router.push({path: "/channel2/", query: {/* base_url: this.base_url, */url: nextURL }}).catch(()=>{});
    } 
  },
  computed: {
    isEnclst(){
      console.log("this.url",this.url)
      if (this.url.split('.').pop() == "enclst"){
        return true
      } else {
        var urlPathList = (new URL(this.url)).pathname.split('/')
        console.log("urlPathList",urlPathList)
        if ("getExpandedPlayList" == urlPathList[1]){
          return true
        }
        return false
      }
    }
  },
  watch: {
    url(newVal){
      this.move(newVal)
    },
    openedPanel(newVal){
      console.log("openedPanel:", newVal)
    }
  },
/*  async mounted() {
    console.log("move without URL")
  },*/
}
</script>