<template>
  <div>
    <div
      v-if="!isPlayList"
    >
      <v-expansion-panel-header
        id="card1"
      >
        <v-list-item>
          <div v-if="$fromHS2">
            <v-list-item-avatar v-if="item.pbtime">
            <!-- https://stackoverflow.com/a/2068371/11073131 -->
            <v-img :src="'https://img.youtube.com/vi/'+item.content + '/0.jpg'"></v-img>
            </v-list-item-avatar>
            <v-badge v-else content="new" left overlap>
              <v-list-item-avatar>
                <!-- https://stackoverflow.com/a/2068371/11073131 -->
                <v-img :src="'https://img.youtube.com/vi/'+item.content + '/0.jpg'"></v-img>
              </v-list-item-avatar>
            </v-badge>          
          </div>
          <div v-else>
            <v-list-item-avatar v-if="isPlayed">
            <!-- https://stackoverflow.com/a/2068371/11073131 -->
            <v-img :src="'https://img.youtube.com/vi/'+item.content + '/0.jpg'"></v-img>
            </v-list-item-avatar>
            <v-badge v-else content="new" left overlap>
              <v-list-item-avatar>
                <!-- https://stackoverflow.com/a/2068371/11073131 -->
                <v-img :src="'https://img.youtube.com/vi/'+item.content + '/0.jpg'"></v-img>
              </v-list-item-avatar>
            </v-badge>
          </div>
          <v-list-item-content>
            <v-list-item-title><YTtitle :item="item"/></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-expansion-panel-header>

      <v-expansion-panel-content v-if="$fromHS2">
        <div v-if="item.live">
          <v-btn small @click="play(item.content, 'live')" class="ms-3"><v-icon>fullscreen_exit</v-icon>start</v-btn>
        </div>
        <div v-else>
          <v-btn small @click="play(item.content, positionStr)" class="ms-3"><v-icon>fullscreen_exit</v-icon>start from</v-btn>
          <VueTimepicker format="HH:mm:ss" v-model="startFrom"/>
        </div>
      </v-expansion-panel-content>

      <v-expansion-panel-content v-if="$fromHS2">
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header>Control</v-expansion-panel-header>
            <v-expansion-panel-content>
        <Control/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-expansion-panel-content>

      <v-expansion-panel-content v-on:resize="resize">
        <youtube style="margin: 0% auto;" :video-id="item.content" ref="youtube" @ready="onPlayerReady" @playing="onPlaying"></youtube>
        duration: {{durationStr}}
      </v-expansion-panel-content>
    </div>
    <PlayList
      v-else
      :item="item"
      ref="playlist"
    />
  </div>
</template>

<script>
import PlayList from "./PlayList"
import Control from '@/components/Control'
import YTtitle from "./YTtitle"
import axios from 'axios'
var sprintf = require('sprintf-js').sprintf
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import { db } from '/src/plugins/db.js'


export default {
  components: {
    PlayList,
    Control,
    YTtitle,
    VueTimepicker,
  },
  props: {
    item: Object,
  },
  data: function(){
    return {
      isPlayed: false,
      controlAvailable: false,
  //    title: "",
      duration: "",
      startFormat: "HH:mm:ss",
      startFrom: {
        HH: '00',
        mm: '00',
        ss: '00'
      }
    }
  },
  methods: {
    play: async function(content, position){
      console.log("play", content)
      const url = sprintf("/play/%s/%s", content, position)
      const res = await axios.get(url)
      console.log("res", res)
    },
    // supported events are https://www.npmjs.com/package/vue-youtube#events
    onPlayerReady: async function(){
      // https://stackoverflow.com/questions/41295517/youtube-video-title-and-duration-without-api-key
      console.log("onPlayerReady!")
      console.log("player", this.$refs.youtube.player)
      this.duration = await this.$refs.youtube.player.getDuration();
      console.log("duration", this.duration)
      if (this.duration == 0 || (this.item.pbtime && this.duration - this.item.pbtime < 30)){
        this.startFrom = {
          HH: '00',
          mm: '00',
          ss: '00'
        }
      }
      // initial size settings

      const cardSize = document.getElementById("card1").getBoundingClientRect()
      console.log("cardSize", cardSize)
      this.$refs.youtube.player.setSize(cardSize.width, cardSize.width * 9 / 16)
/*      console.log("this.duration",this.duration)
      this.title = this.$refs.youtube.player.getVideoData().title*/
    },
    onPlaying: async function(){
      console.log("onPlaying!")
      console.log("content", this.item.content)
      console.log("db", db)
      this.isPlayed = true
      await db.Contents.put({ id: this.item.content, played: true});
    },
    resize: function(){
      // resize yt-player
//      const cardSize = document.getElementById("card1").getBoundingClientRect()
      if (document.getElementById("card1") != null) {
//        console.log("document.getElementById(card1)",document.getElementById("card1"))
        const cardSize = document.getElementById("card1").getBoundingClientRect()
        if (this.$refs.youtube != null) {
          this.$refs.youtube.player.setSize(cardSize.width, cardSize.width * 9 / 16)
        }
      }
    },
    handleMessage: function(message){
      switch (message.status) {
        case "OmxPlayerReady":
          this.controlAvailable = true
          break
        case "pbtime":
          if (this.item.content == message.contents){
//            console.log("update:",message.pbtime)
            this.item.pbtime = message.pbtime
//            console.log("this.item", this.item)

            const pbtime = this.item.pbtime
//            console.log("pbtime",pbtime)
            this.startFrom.HH = String(Math.trunc(pbtime / 3600)).padStart(2,'0')
            this.startFrom.mm = String(Math.trunc((pbtime % 3600)/60)).padStart(2,'0')
            this.startFrom.ss = String(pbtime % 60).padStart(2,'0')
          }
          break
        case "expandpl":
          if (this.item.content == message.playlist){
            if (message.result == "OK"){
              this.$refs.playlist.expandFinishedOK()
            } else {
              this.$refs.playlist.expandFinishedNG()
            }
          }
      }
    },
  },
  watch: {
    item: function(newItem){
//      console.log("duration", this.duration)
//      console.log("pbtime", newItem.pbtime)
//      console.log("this.duration - newItem.pbtime > 30", this.duration - newItem.pbtime > 30)
//      if (newItem.pbtime && this.duration - newItem.pbtime > 30){
      if (newItem.pbtime){
        const pbtime = newItem.pbtime
        console.log("pbtime",pbtime)
        this.startFrom.HH = String(Math.trunc(pbtime / 3600)).padStart(2,'0')
        this.startFrom.mm = String(Math.trunc((pbtime % 3600)/60)).padStart(2,'0')
        this.startFrom.ss = String(pbtime % 60).padStart(2,'0')
      }
    },
    $message: function(newMessage){
      if (this.$fromHS2){
          console.log("message", newMessage)
          this.handleMessage(newMessage)
      }
    }
  },
  computed: {
    isPlayList: function(){
//      console.log("this.item.content.substring(0,2)",this.item.content.substring(0,2))
      if (this.item.content.substring(0,2) == 'PL' ){
        return true
      } else {
        return false
      }
    },
    positionStr: function(){
      return sprintf("%s:%s:%s", this.startFrom.HH, this.startFrom.mm, this.startFrom.ss)
    },
    durationStr: function(){
      if (this.duration >= 3600){
        let hours = Math.trunc(this.duration / 3600)
        let minutes = Math.trunc((this.duration % 3600) / 60)
        let seconds  = this.duration % 60
        return ""+ hours +":"+minutes+":"+seconds
      } else if (this.duration >= 60) {
        let minutes = Math.trunc(this.duration / 60)
        let seconds  = this.duration % 60
        return ""+minutes+":"+seconds
      } else {
        return ""+this.duration+" sec"
      }
    }
  },
  async mounted() {
    window.addEventListener('resize', this.resize);
    const Content = await db.Contents.get({ id: this.item.content});
    console.log("Content", Content)
    if (typeof Content !== "undefined") {
      this.isPlayed = Content.played
    }
  }
}
</script>